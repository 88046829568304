export const GET_MOVIE_LIST = 'GET_MOVIE_LIST'

export const SET_AVAILABLE_FILM = 'SET_AVAILABLE_FILM'
export const SET_COMMING_FILM = 'SET_COMMING_FILM'



export const THEATHER_CALENDAR_INFOR = 'THEATHER_CALENDAR_INFOR'

export const MOVIE_CALENDER_INFOR = 'MOVIE_CALENDER_INFOR'


export const USER_REDUCER = 'USER_REDUCER'


export const TICKET_INFOR_REDUCER = 'TICKET_INFOR_REDUCER'


export const BOOKING_SEAT_REDUCER = 'BOOKING_SEAT_REDUCER'


export const BOOKING_SEAT_REMOVE_REDUCER = 'BOOKING_SEAT_REMOVE_REDUCER'
export const CONFIRM_BOOKING_REDUCER = 'CONFIRM_BOOKING_REDUCER'
export const USER_BOOKING_INFOR_REDUCER = 'USER_BOOKING_INFOR_REDUCER'
export const OPEN_MODAL_BOOKING_DETAILS = 'OPEN_MODAL_BOOKING_DETAILS'
export const CLOSE_MODAL_BOOKING_DETAILS = 'CLOSE_MODAL_BOOKING_DETAILS'


export const  OPEN_LOADING = 'OPEN_LOADING'
export const CLOSE_LOADING = 'CLOSE_LOADING'


export const SWITCH_TAB = 'SWITCH_TAB'


export const  ACTIVE_CHANGE = ' ACTIVE_CHANGE'



export const ORDER_BOOKING_FROM_SERVER = 'ORDER_BOOKING_FROM_SERVER'
export const FILM_INFOR_ADMIN = 'FILM_INFOR_ADMIN'


export const REMOVE_ALL_TEMP_BOOKING = 'REMOVE_ALL_TEMP_BOOKING'
export const REMOVE_ALL_USER_INFOR = 'REMOVE_ALL_USER_INFOR'