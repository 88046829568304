export const DOMAIN = 'http://movieapi.cyberlearn.vn/api/'
export const TOKEN = 'access_token'
export const GroupId = 'GP01'
export const NAME_USER = 'NAME_USER'
export const ACCOUNT = 'ACCOUNT'
export const API_KEY = "c2e067a406e2f67ff55edc53d61c2145"





